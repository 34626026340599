import React from "react";
import CardDerecha from "./CardDerecha";
import CardIzquierda from "./CardIzquierda";
import Cardmobile from "./Cardmobile";
import './styles/miestilo.css'


// {exercises} en vez de props, eso se llama destructuracion, ni idea que hace
const CardList = ({trabajos}) => (
    <div>
        {
            trabajos.map(trabajo => {
                if (trabajo.id%2 === 0) {
                    return (
                        <div>
                            <div className={"micontenedot d-none d-xl-block"}>
                                <CardIzquierda
                                    key={trabajo.id}
                                    {...trabajo}
                                />
                            </div>
                            <div className={'d-lg-block d-xl-none d-md-block d-sm-block'}>
                                <Cardmobile
                                    key={trabajo.id + 2}
                                    {...trabajo}
                                />
                            </div>
                        </div>

                    )
                } else {
                    return (
                        <div>
                            <div className={"micontenedot d-none d-xl-block"}>
                                <CardDerecha
                                    key={trabajo.id}
                                    {...trabajo}
                                />
                            </div>
                            <div className={'d-lg-block d-xl-none d-md-block d-sm-block'}>
                                <Cardmobile
                                    key={trabajo.id + 2}
                                    {...trabajo}
                                />
                            </div>
                        </div>
                    )
                }

            })
        }
    </div>
)

export default CardList

