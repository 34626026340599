import React from "react";
import {MDBCardImage} from 'mdb-react-ui-kit';
import './styles/miestilo.css'


const Cardmobile = ({texto, img, titulo}) => {

    return (
        <div className={'container'}>
            <div className={'mt-3'}>
                <MDBCardImage src={img} position='top' alt='' />
            </div>
            <div className={'centrartexto'}>
                <h2>{titulo}</h2>
                <p>{texto}</p>
            </div>
            <br/>
        </div>
    )
}

export default Cardmobile
