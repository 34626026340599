import React from "react";
import {
    MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem,
    MDBCarouselElement,
    MDBCarouselCaption,
} from 'mdb-react-ui-kit';
import 'holderjs';
import img1 from '../images/8_recortada.webp'; // with import
import img2 from '../images/6_cortada.webp'; // with import
import img3 from '../images/11_cortado.webp'; // with import


const CarouselComponent = () => (
    <div className='mb-3 mt-4'>
        <br/><br/>
        <MDBCarousel showControls>
            <MDBCarouselInner>
                <MDBCarouselItem className='active'>
                    <MDBCarouselElement src={img1} alt='...' />
                    <div className='mask' style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}/>
                    <MDBCarouselCaption>
                        <h5>24/7 Surveillance</h5>
                        <p>Strategically placed cameras.</p>
                    </MDBCarouselCaption>
                </MDBCarouselItem>

                <MDBCarouselItem>
                    <MDBCarouselElement src={img2} alt='...' />
                    <div className='mask' style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}/>
                    <MDBCarouselCaption>
                        <h5>Authorized Personnel</h5>
                        <p>Professionals with best experience.</p>
                    </MDBCarouselCaption>
                </MDBCarouselItem>

                <MDBCarouselItem>
                    <MDBCarouselElement src={img3} alt='...' />
                    <div className='mask' style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}/>
                    <MDBCarouselCaption>
                        <h5>Automation</h5>
                        <p>We can create your perfect environment.</p>
                    </MDBCarouselCaption>
                </MDBCarouselItem>
            </MDBCarouselInner>
        </MDBCarousel>
    </div>
)

export default CarouselComponent
