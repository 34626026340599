import React, { useEffect, useState} from "react";
import  CardList from "./CardList"
import  Footer from "./Footer"
import  GalleryList from "./GalleryList"
import  Navbar from "./Navbar"
import  Titulo from "./Titulo"
import  Carousel from "./Carousel"
import  Loading from "./Loading"
import "./styles/miestilo.css"
import 'animate.css';
import {default as dataTrabajos} from '../data.js';

const App = () => {
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading2(false)
        }, 2800)

        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])

    return loading ? (
            <div className={loading2 ? '' : 'animate__animated animate__fadeOut animate__slow'}>
                <Loading />
            </div>
        ) :
        (
            <div className={'animate__animated animate__fadeIn'}>
                <Navbar/>
                <Carousel/>
                <div id='jobs'>
                    <Titulo texto={'JOBS'}/>
                </div>
                <CardList trabajos={dataTrabajos.carrusel}/>
                <Titulo texto={'GALLERY'}/>
                <div id='gallery'>
                    <GalleryList fotos={dataTrabajos.galeria}/>
                </div>
                <div id={'contact'}>
                    <Footer/>
                </div>
            </div>
        )
}

export default App
