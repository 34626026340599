import React from "react";
import { Row, Col, Image  } from 'react-bootstrap';
import './styles/miestilo.css'

const CardIzquierda = ({texto, img, titulo}) => {

    return (
        <div>
            <Row>
                <Col>
                    <Image className={'responsive-full'} src={img} alt="imagen" rounded />
                </Col>
                <Col>
                    <div className={'alinearTexto-card'}>
                        <h2>{titulo}</h2>
                        <p>{texto}</p>
                    </div>
                </Col>
            </Row>
            <br/>
        </div>
    )
}

export default CardIzquierda
