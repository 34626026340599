import React, { useState } from 'react';
import './styles/miestilo.css'
import logo from '../images/logo.png'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
    MDBIcon
} from 'mdb-react-ui-kit';

const NavbarCompoentn = () => {
    const [showNav, setShowNav] = useState(true);

    return (
        <div>
            <MDBNavbar fixed='top' expand='lg' dark style={{backgroundColor: '#000000'}} id="navbar-id">
                <MDBContainer fluid className={'container'}>
                    <MDBNavbarBrand href='#' className={'text-color'}>
                        <img
                            src={logo}
                            height='50'
                            alt=''
                            loading='lazy'
                        />
                    </MDBNavbarBrand>
                    <MDBNavbarToggler
                        type='button'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setShowNav(!showNav)}
                    >
                        <MDBIcon icon='bars' fas className={'text-color'} />
                    </MDBNavbarToggler>
                    <MDBCollapse navbar show={showNav}>
                        <MDBNavbarNav>
                            <MDBNavbarItem>
                                <MDBNavbarLink aria-current='page' href='#jobs' className={'text-color hover'}>
                                    <MDBIcon fas icon="briefcase" className={'mx-1'} />
                                    Jobs
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink aria-current='page' href='#gallery' className={'text-color hover'}>
                                    <MDBIcon fas icon="image" className={'mx-1'}/>
                                    Gallery
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink aria-current='page' href='#contact' className={'text-color hover'}>
                                    <MDBIcon fas icon="envelope" className={'mx-1'}/>
                                    Contact
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        </div>

    );
}

export default NavbarCompoentn
