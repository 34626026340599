import React from "react";
import './styles/miestilo.css'
import {MDBFooter} from "mdb-react-ui-kit";

const Titulo = ({texto}) => (
    <div>
        <MDBFooter style={{backgroundColor: '#000000'}} className='text-center text-lg-left mx-5'>
            <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div style={{ color: '#ffffff' }}>
                    <h2>{texto}</h2>
                </div>
            </div>
        </MDBFooter>
    </div>
)

export default Titulo

