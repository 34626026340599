import React from "react";
import './styles/miestilo.css'


import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

const FooterComponent = () => (
    <div>
        <MDBFooter className='text-lg-left'>
            <div style={{backgroundColor: 'rgba(0,0,0,0.94)'}}>
                <MDBContainer  className='p-4'>
                    <MDBRow>
                        <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                            <h5 className='text-uppercase text-color'>Business Hours</h5>
                            <table className={'text-color-suave'}>
                                <tbody>
                                <tr>
                                    <th>Mon:</th>
                                    <td><span>8:00 AM – 6:00 PM</span></td>
                                </tr>
                                <tr>
                                    <th>Tue:</th>
                                    <td><span>8:00 AM – 6:00 PM</span></td>
                                </tr>
                                <tr>
                                    <th>Wed:</th>
                                    <td><span>8:00 AM – 6:00 PM</span></td>
                                </tr>
                                <tr>
                                    <th>Thu:</th>
                                    <td><span>8:00 AM – 6:00 PM</span></td>
                                </tr>
                                <tr>
                                    <th>Fri:</th>
                                    <td><span>8:00 AM – 6:00 PM</span></td>
                                </tr>
                                <tr>
                                    <th>Sat:</th>
                                    <td><span>9:00 AM – 3:00 PM</span></td>
                                </tr>
                                <tr>
                                    <th>Sun:</th>
                                    <td><span>Closed</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </MDBCol>
                        <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                            <h5 className='text-uppercase text-color'>CONTACT US</h5>
                            <table>
                                <tbody>
                                <tr>
                                    <th><th><i className="fas fa-phone fa-lg"/></th>
                                    </th>
                                    <td>
                                        <a href="tel:+1-914-510-4262" className={'text-color-suave hover'}>
                                            +1-914-510-4262
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <th><i className="fas fa-envelope fa-lg"/></th>
                                    <td>
                                        <a href="mailto:Infraredsolutionsny@gmail.com" className={'text-color-suave hover'}>
                                            Infraredsolutionsny@gmail.com
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

            </div>

            <div  className='text-center p-3' style={{ backgroundColor: 'rgb(0,0,0)' }}>
                &copy; {new Date().getFullYear()}{' '}
                <a className={'text-light'} href={'#'}>
                    Infrared Solution
                </a>
            </div>
        </MDBFooter>
    </div>
)

export default FooterComponent

