import React from "react";
import * as mdb from 'mdb-ui-kit';

const  GalleryCompoent = ({fotos}) => (
    <div
        id="carouselMultiItemExample"
        className="carousel slide carousel-dark text-center"
        data-mdb-ride="carousel"
    >
        <div className="d-flex justify-content-center">
            <button
                className="carousel-control-prev position-relative"
                type="button"
                data-mdb-target="#carouselMultiItemExample"
                data-mdb-slide="prev"
            >
                <span className="carousel-control-prev-icon" aria-hidden="true"/>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next position-relative"
                type="button"
                data-mdb-target="#carouselMultiItemExample"
                data-mdb-slide="next"
            >
                <span className="carousel-control-next-icon" aria-hidden="true"/>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
        <div className="carousel-inner py-4">

            {/*Items carrusel*/}
            <div>
                <div className="carousel-item active">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4">
                                <div className="card">
                                    <img
                                        src={fotos[0].img1}
                                        className="card-img-top"
                                        alt="..."
                                    />

                                </div>
                            </div>

                            <div className="col-lg-4 d-none d-lg-block">
                                <div className="card">
                                    <img
                                        src={fotos[0].img2}
                                        className="card-img-top"
                                        alt="..."
                                    />

                                </div>
                            </div>

                            <div className="col-lg-4 d-none d-lg-block">
                                <div className="card">
                                    <img
                                        src={fotos[0].img3}
                                        className="card-img-top"
                                        alt="..."
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="card">
                                    <img
                                        src={fotos[1].img1}
                                        className="card-img-top"
                                        alt="..."
                                    />

                                </div>
                            </div>

                            <div className="col-lg-4 d-none d-lg-block">
                                <div className="card">
                                    <img
                                        src={fotos[1].img2}
                                        className="card-img-top"
                                        alt="..."
                                    />

                                </div>
                            </div>

                            <div className="col-lg-4 d-none d-lg-block">
                                <div className="card">
                                    <img
                                        src={fotos[1].img3}
                                        className="card-img-top"
                                        alt="..."
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
                                <div className="card">
                                    <img
                                        src={fotos[2].img1}
                                        className="card-img-top"
                                        alt="..."
                                    />

                                </div>
                            </div>

                            <div className="col-lg-4 mb-4 mb-lg-0 d-none d-lg-block">
                                <div className="card">
                                    <img
                                        src={fotos[2].img2}
                                        className="card-img-top"
                                        alt="..."
                                    />

                                </div>
                            </div>

                            <div className="col-lg-4 mb-4 mb-lg-0 d-none d-lg-block">
                                <div className="card">
                                    <img
                                        src={fotos[2].img3}
                                        className="card-img-top"
                                        alt="..."
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    )

export default GalleryCompoent
