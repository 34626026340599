import React from 'react';
import ReactDOM from 'react-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import App from './components/App'

const container = document.getElementById('root')

ReactDOM.render(
    <App />
    , container )
