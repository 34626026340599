import React from "react";
import './styles/miestilo.css'
import logo from '../images/logo.png'



const Loading = () => (
    <div style={{backgroundColor: '#000000'}} className={'pantalla-completa contenedor'}>
        <img
            className={'animate__animated animate__zoomIn animacion welcome-logo'}
            src={logo}
            height='50'
            alt=''
            loading='lazy'
        />
    </div>
)

export default Loading
