import 'holderjs';
import imgCarousel1 from './images/9_cotado.webp';
// import imgCarousel2 from './images/serie.jpg';
import galeria1 from './images/1_galeria.webp';
import galeria2 from './images/2_galeria.webp';
import galeria3 from './images/3_galeria.webp';
import galeria4 from './images/galeria_4.webp';
import galeria5 from './images/5_galeria.webp';
import galeria6 from './images/7_galeria.webp';
import galeria7 from './images/11_galeria.webp';
import galeria8 from './images/13_galeria.webp';
import galeria9 from './images/14_galeria.webp';

const data =
    {
        carrusel: [
            {
                "id": 1,
                "titulo": "We provide high quality security camera installation professionally by our team",
                "texto": 'We have a large catalog to keep you safe. Great specialized team to install the security solution in your company or residence. Installation of surveillance cameras or security cameras are cameras that are used for video surveillance, we take care of their installation.',
                "img": imgCarousel1

            },
            {
                "id": 2,
                "titulo": "You just have to imagine it and we do the rest",
                "texto": 'Home automation allows us to improve our quality of life at all levels: comfort, safety, greater savings. We offer multiple automation devices so that you can finally achieve your best dream living environment. Our experts will offer you the best ways to bring that room you want so much.',
                "img": galeria5
            }
        ],
        galeria: [
            {
                "id": 1,
                "img1": galeria1,
                "img2": galeria2,
                "img3": galeria3,
            },
            {
                "id": 2,
                "img1": galeria4,
                "img2": galeria5,
                "img3": galeria6,
            },
            {
                "id": 3,
                "img1": galeria7,
                "img2": galeria8,
                "img3": galeria9,
            },
        ]
    }

export default data
